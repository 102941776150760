<template>
	<div class="page">
		<Tables v-if="ishow" ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage"
			:isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true" :showSeq="false"
			:showOutput="true" @getList="getList" @clearSearch="clearSearch" :headerShow="true">
			<!--搜索自定义内容-->
			<!--el-form-item slot="search-item">
				<el-select size="small" v-model="searchFormData.searchType" placeholder="查询条件">
					<el-option label="微信名" :value="searchType.name.value">
					</el-option>
					<el-option label="用户ID" :value="searchType.id.value">
					</el-option>
				</el-select>
			</el-form-item-->
			<el-form-item slot="search-item">
				<el-input size="small" v-model="searchFormData.fans_id" style="width: 120px;" clearable
					placeholder="用户ID"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input size="small" v-model="searchFormData.phone" clearable placeholder="请输入手机号查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select size="small" v-model="searchFormData.city" style="width: 120px;" clearable placeholder="归属城市">
					<el-option v-for="(item, index) in $userArea" :label="item" :value="index">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select size="small" v-model="searchFormData.is_vip" style="width: 120px;" clearable placeholder="用户类型">
					<el-option v-for="(item, index) in $userType" :label="item" :value="index">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-date-picker v-model="searchFormData.register_time" style="width: 240px;" clearable type="daterange"
					format="yyyy-MM-dd" value-format="yyyy-MM-dd" start-placeholder="开始注册时间" end-placeholder="结束注册时间"
					size="small">
				</el-date-picker>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-date-picker v-model="searchFormData.last_time" style="width: 240px;" clearable type="daterange"
					format="yyyy-MM-dd" value-format="yyyy-MM-dd" start-placeholder="开始最后登录" end-placeholder="结束最后登录"
					size="small">
				</el-date-picker>
			</el-form-item>
			<!--el-form-item slot="search-item">
				<el-select size="small" v-model="searchFormData.sortType" clearable placeholder="排序条件">
					<el-option label="用户ID" value="fans_id">
					</el-option>
					<el-option label="余额" value="balance">
					</el-option>
					<el-option label="总消费" value="pay_money">
					</el-option>
				</el-select>
			</el-form-item-->
			<!--<el-form-item slot="search-item">
				<el-select v-model="searchFormData.group_id" size="small" filterable reserve-keyword clearable
					placeholder="按所属小区查询" :remote-method="remoteGroup" :loading="groupLoading">
					<el-option v-for="item in groupList" :key="item.group_id" :label="item.group_name"
						:value="item.group_id">
					</el-option>
				</el-select>
			</el-form-item> -->





			<div slot="action-item" size="small">
				<div style="position: relative;margin-right:10px">
					<el-button @click="checkHeadShow = true" size="small" plain>表头选择
					</el-button>
					<div class="checkHead" v-if="checkHeadShow">
						<div class="heads">
							<div>
								请选择列表中要显示的信息
							</div>
							<el-button type="text" icon="el-icon-close" class="btn"
								@click="checkHeadShow = false"></el-button>
						</div>

						<div class="item">
							<el-checkbox :checked="ischeckAll" @change="checkAll" label="全部" size="large" />
						</div>

						<div class="item">
							<el-checkbox checked :disabled="true" label="手机号" size="large" />
						</div>
						<draggable v-model="rowData" @end="changea()">
							<div class="item" v-for="(item, index) in rowData" :key=index>
								<el-checkbox v-model="item.checked" @change="changea" :label="item.title" size="large" />
								<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 256 256">
									<path fill="#c0c4cc"
										d="M222 128a6 6 0 0 1-6 6H40a6 6 0 0 1 0-12h176a6 6 0 0 1 6 6ZM40 70h176a6 6 0 0 0 0-12H40a6 6 0 0 0 0 12Zm176 116H40a6 6 0 0 0 0 12h176a6 6 0 0 0 0-12Z" />
								</svg>
							</div>
						</draggable>
						<div class="item">
							<el-checkbox checked :disabled="true" label="操作" size="large" />
						</div>
					</div>
				</div>
			</div>
			<!--表格内容-->
			<vxe-table-column slot="table-item" title="手机号" field="phone" align="center" :min-width="120" fixed="left">
				<template v-slot="{ row }">
					<span :class="row.status == 0 ? 'txt-disable' : ''">{{ row.phone }}</span>
				</template>
			</vxe-table-column>

			<div slot="table-item">
				<div v-for="(item, index) in rowData" :key="index">
					<vxe-table-column :title="item.title" :align="item.align" :min-width="item.width" class="vxe-cell"
						v-if="item.checked" :field="item.checked?item.field:''">
						<template v-slot="{ row }">
							<div v-if="item.field == 'car'">
								<a @click="detailOne(row, 3)" style="color:blue;cursor:pointer">{{ row.car }}</a>
							</div>
							<div v-else-if="item.field == 'card'">
								<a @click="detailOne(row, 4)" style="color:blue;cursor:pointer">{{ row.card }}</a>
							</div>
							<div v-else-if="item.field == 'energy'">
								<a @click="detailOne(row, 6)" style="color:blue;cursor:pointer">{{ row.energy }}</a>
							</div>
							<div v-else-if="item.field == 'energy_use'">
								<a @click="detailOne(row, 6)" style="color:blue;cursor:pointer">{{ row.energy_use }}</a>
							</div>
							<div v-else-if="item.field == 'coupon'">
								<a @click="detailOne(row, 5)" style="color:blue;cursor:pointer">{{ row.coupon }}</a>
							</div>
							<div v-else-if="item.field == 'city'">
								<span :class="row.status == 0 ? 'txt-disable' : ''">{{ types.city[row.city] }}</span>
							</div>
							<div v-else-if="item.field == 'is_white_text'">
								<span
									:class="row.status == 0 ? 'txt-disable' : (row.is_white === 10 ? '' : 'warn-color')">{{
										row.is_white
										=== 10 ? '否' : '是' }}</span>
							</div>
							<div v-else>
								<span :class="row.status == 0 ? 'txt-disable' : ''">{{ row[item.field] }}</span>
							</div>
						</template>
					</vxe-table-column>
				</div>
			</div>


		

			<vxe-table-column slot="table-item" title="操作" align="center" width="320" fixed="right">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="detailOne(row, 1)" v-if="$hasAccess('user.fans/view')">详情
					</el-button>
					<el-button size="small" plain @click="rechargeChargingOne(row)"
						v-if="$hasAccess('user.fans/recharge')">充扣款
					</el-button>

					<el-button size="small" plain @click="userFansStatusOper(row)" v-if="$hasAccess('user.fans/charging')"
						:style="row.status == 1 ? '' : 'background:red;color:white'">
						{{ row.status == 1 ? '禁用' : '恢复' }}
					</el-button>
					<el-button size="small" plain @click="rechargeDkChargingOne(row)"
							   v-if="$hasAccess('user.fans/recharge')">清除
					</el-button>
				</template>
			</vxe-table-column>
		</Tables>
		<!-- 详情 -->
		<el-dialog title="用户详情" class="detailK" :visible.sync="detailDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="850px">

			<div class="navBar">
				<div>
					手机号：{{ detailDialogFormData.phone }}
				</div>
				<div>
					用户ID:{{ detailDialogFormData.fans_id }}
				</div>
				<div v-if="detailDialogFormData.country">
					归属地城市:
					{{ detailDialogFormData.country }},
					{{ detailDialogFormData.province }}, {{ detailDialogFormData.city }}
				</div>
				<div v-if="!detailDialogFormData.country">
					归属地城市:未知
				</div>
			</div>


			<el-tabs type="border-card" v-model="tabIndex">
				<el-tab-pane label="消费信息" name="1">
					<el-form ref="detailForm" label-width="110px" class="form" size="mini">
						<el-form-item label="可用余额：">{{ detailDialogFormData.balance }}</el-form-item>

						<el-form-item label="总消费：">{{ detailDialogFormData.pay_money }}</el-form-item>

						<el-form-item label="总充值：">{{ detailDialogFormData.recharge_money }}</el-form-item>

						<el-form-item label="当月累计度数：">{{ detailDialogFormData.all_electric }}</el-form-item>

						<el-form-item label="总充电度数：">{{ detailDialogFormData.total_electric }}</el-form-item>

						<el-form-item label="VIP:">{{ detailDialogFormData.is_vip }}</el-form-item>

						<el-form-item label="VIP到期时间:">{{ detailDialogFormData.vip_deadline }}</el-form-item>

						<el-form-item label="VIP累计省钱(中断清零):">{{ detailDialogFormData.vip_save_money }}</el-form-item>

					</el-form>
				</el-tab-pane>
				<el-tab-pane label="注册信息" name="2">
					<el-form ref="detailForm" label-width="110px" class="form" size="mini">
						<el-form-item label="注册来源：">{{ types.from[detailDialogFormData.from] || '未知' }}</el-form-item>

						<el-form-item label="注册时间：">{{ detailDialogFormData.register_time }}</el-form-item>

						<el-form-item label="最近登录时间：">{{ detailDialogFormData.last_time }}</el-form-item>

						<el-form-item label="用户来源：">{{ detailDialogFormData.from_phone }}</el-form-item>

						<el-form-item label="邀请用户："></el-form-item>
					</el-form>


				</el-tab-pane>
				<el-tab-pane label="车辆" name="3">
					<el-table :data="this.detailCar" border class="data_table">
						<!--表格内容-->
						<el-table-column prop="bname" label="品牌" width="100"></el-table-column>
						<el-table-column prop="qname" label="型号" width="180"></el-table-column>
						<el-table-column prop="car_no" label="车牌" width="100"></el-table-column>
						<el-table-column prop="used" label="车辆用途" width="180">
							<template v-slot="{ row }">
								{{ carUsed[row.used] || "未填" }}
							</template>
						</el-table-column>
						<el-table-column prop="update_time" label="绑定(修改)时间"></el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="电卡" name="4">
					<el-table :data="this.detailCardInfo" border class="data_table">
						<!--表格内容-->
						<el-table-column prop="card_id" label="ID"></el-table-column>
						<el-table-column prop="card_name" label="电卡名称"></el-table-column>
						<el-table-column prop="card_balance" label="余额"></el-table-column>
						<el-table-column prop="start_time" label="开始时间"></el-table-column>
						<el-table-column prop="end_time" label="结束时间"></el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="优惠券" name="5">
					<el-table :data="this.detailCon" border class="data_table">
						<!--表格内容-->
						<el-table-column prop="coupon_id" label="ID" width=""></el-table-column>
						<el-table-column prop="coupon_name" label="名称" width=""></el-table-column>
						<el-table-column prop="start_time" label="开始时间" width=""></el-table-column>
						<el-table-column prop="end_time" label="结束时间" width=""></el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="能量值" name="6">
					<div class="navBar" style="line-height:40px">
						<div>已用能量值：{{ detailDialogFormData.energy_use }}</div>
						<div>能量值余额：{{ detailDialogFormData.energy }}</div>
						<div><el-button size="small" @click="updateEnergy">增减能量值</el-button></div>
					</div>
					<el-table :data="this.energyList" border class="data_table">
						<!--表格内容-->
						<el-table-column prop="tm" label="时间"></el-table-column>
						<el-table-column prop="tp_text" label="类型"></el-table-column>
						<el-table-column prop="num" label="积分变化">
							<template #default="{row}">
								<div :style="{ color: row.num > 0 ? 'black' : 'red' }">{{ row.num > 0?'+':''}}{{ row.num }}</div>
							</template>
						</el-table-column>
						<el-table-column prop="numnew" label="积分余额" width=""></el-table-column>
					</el-table>
					<el-pagination layout="prev, pager, next" :total="energyTotal" @current-change="energyGet"></el-pagination>
				</el-tab-pane>
				<el-tab-pane label="白名单" name="7">
					<el-form ref="detailForm" label-width="110px" class="form" size="mini">
						<el-form-item label="白名单：">
							<!-- {{types.is_white[detailDialogFormData.is_white]||'未知'}} -->
							<el-switch v-model="detailDialogFormData.is_white" @change="onFansWhiteChange">
							</el-switch>
						</el-form-item>
					</el-form>
				</el-tab-pane>
			</el-tabs>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消 </el-button>
			</div>
		</el-dialog>





		<!-- 能量值修改 -->
		<el-dialog title="增减能量值" :visible.sync="updateEnergyShow" :close-on-click-modal="false" :close-on-press-escape="false"
			width="500px">
			<div class="navBar">
				<div>
					手机号：{{ detailDialogFormData.phone }}
				</div>
				<div>
					已用能量值: {{ detailDialogFormData.energy_use }}
				</div>
				<div>
					能量值余额: {{ detailDialogFormData.energy }}
				</div>
			</div>

			<el-form :model="updateEnergyData" :rules="updateEnergyRule" ref="updateEnergyForm" label-width="60px"
				class="form">
				<el-form-item label="类型" prop="type">
					<el-select size="small" v-model="updateEnergyData.type" style="width: 80%;" placeholder="">
						<el-option label="增加" :value="1">
						</el-option>
						<el-option label="减少" :value="2">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="数量" prop="num">
					<el-input-number v-model="updateEnergyData.num" controls-position="right" :min="0" :step="1"
						:precision="1" style="width: 80%;"></el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveUpdateEnergy()"
					v-if="$hasAccess('user.fans/recharge')">确 认</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="updateEnergyShow = false">取 消
				</el-button>
			</div>
		</el-dialog>



		<!-- 充扣款 -->
		<el-dialog title="充扣款" :visible.sync="rechargeChargingDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="500px">
			<el-form :model="rechargeChargingDialogFormData" :rules="rechargeChargingRules" ref="rechargeChargingForm"
				label-width="140px" class="form">
				<el-form-item label="手机号" size="mini">{{ rechargeChargingDialogFormOriData.phone }}</el-form-item>

				<el-form-item label="类型" prop="type">
					<el-select size="small" v-model="rechargeChargingDialogFormData.type" style="width: 80%;"
						@change="changeRechargeChargingType" placeholder="">
						<el-option label="充值" :value="1">
						</el-option>
						<el-option label="扣款" :value="2">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="金额" prop="money">
					<el-input-number v-model="rechargeChargingDialogFormData.money" controls-position="right" :min="0"
						:max="rechargeChargingMax" :step="1" :precision="2" style="width: 80%;"></el-input-number>
				</el-form-item>
				<el-form-item label="备注" prop="remark" v-if="rechargeChargingDialogFormData.type == 1">
					<el-input v-model="rechargeChargingDialogFormData.remark" controls-position="right" :min="0" :step="1"
						:precision="2" style="width: 80%;"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveRechargeCharging()"
					:disabled="editDisabled" v-if="$hasAccess('user.fans/recharge')">确 认</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="rechargeChargingDialogShow = false">取 消
				</el-button>
			</div>
		</el-dialog>

		<!-- 清除电卡和VIP -->
		<el-dialog title="清除" :visible.sync="rechargeDkDialogShow" :close-on-click-modal="false"
				   :close-on-press-escape="false" width="500px">
			<el-form :model="rechargeDkDialogFormData" :rules="rechargeChargingRules" ref="rechargeDkChargingForm"
					 label-width="140px" class="form">
				<el-form-item label="类型" prop="type">
					<el-select size="small" v-model="rechargeDkDialogFormData.type" style="width: 80%;"
							   @change="changeRechargeChargingType" placeholder="">
						<el-option label="电卡" :value="1">
						</el-option>
						<el-option label="VIP" :value="2">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveRechargeDkCharging()"
						    v-if="$hasAccess('user.fans/recharge')">清 除</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="rechargeDkDialogShow = false">取 消
				</el-button>
			</div>
		</el-dialog>

	</div>
</template>
<script>
import Tables from '@/components/tables'
import draggable from 'vuedraggable'
export default {
	name: 'user-fans-list',
	components: {
		Tables,
		draggable
	},
	data() {
		return {
			// 表格信息
			tableName: '用户列表',
			isLoading: false,
			tableData: [],
			totalPage: 0,
			groupList: [],
			groupLoading: "",
			// searchType: {
			// 	name: {
			// 		value: 10,
			// 		desc: "请输入微信名查询"
			// 	},
			// 	id: {
			// 		value: 20,
			// 		desc: "请输入用户ID查询 "
			// 	}
			// },
			searchFormData: {},
			carUsed: ["未填", "物流车/商用车", "专快车", "出租车", "私家车"],
			types: {
				gender: {
					1: '男',
					2: '女'
				},
				is_white: {
					10: '否',
					20: '是'
				},
				type: {
					10: '临时',
					20: '会员'
				},
				grade: {
					10: '月',
					20: '年'
				},
				city: {
					10: '全部',
					20: '苏州',
					30: '无锡'
				},
				from: {
					miniprograms: '微信小程序'
				}
			},
			// 详情
			detailDialogShow: false,
			detailDialogFormData: {},

			// 充扣款
			rechargeChargingDialogShow: false,
			rechargeDkDialogShow:false,
			rechargeChargingDialogFormData: {},
			rechargeChargingDialogFormOriData: {},
			rechargeDkDialogFormData: {},
			rechargeChargingRules: {
				money: [{
					required: true,
					message: '请输入充值金额',
					trigger: 'change'
				}],
			},
			rowData: [
				{
					field: "fans_id",
					width: 80,
					align: 'center',
					title: '用户ID',
					checked: true
				},
				{
					field: "balance",
					width: 80,
					align: 'center',
					title: '可用余额', checked: true
				},
				{
					field: "pay_money",
					width: 80,
					align: 'center',
					title: '总消费', checked: true
				},
				{
					field: "recharge_money",
					width: 80,
					align: 'center',
					title: '总充值', checked: true
				},
				{
					field: "total_electric",
					width: 80,
					align: 'center',
					title: '总度数', checked: true
				},
				{
					field: "all_electric",
					width: 80,
					align: 'center',
					title: '当月累计度数', checked: true
				},
				{
					field: "invite",
					width: 80,
					align: 'center',
					title: '邀请用户', checked: true
				},
				{
					field: "from_phone",
					width: 80,
					align: 'center',
					title: '用户来源', checked: true
				},
				{
					field: "car",
					width: 80,
					align: 'center',
					title: '车辆', checked: true
				},
				{
					field: "card",
					width: 80,
					align: 'center',
					title: '电卡', checked: true
				},
				{
					field: "energy",
					width: 80,
					align: 'center',
					title: '能量值余额', checked: true
				},
				{
					field: "energy_use",
					width: 80,
					align: 'center',
					title: '已用能量值', checked: true
				},
				{
					field: "coupon",
					width: 80,
					align: 'center',
					title: '优惠券', checked: true
				},
				{
					field: "city",
					width: 80,
					align: 'center',
					title: '归属城市', checked: true
				},
				{
					field: "is_white_text",
					width: 80,
					align: 'center',
					title: '白名单', checked: true
				},
				{
					field: "is_vip",
					width: 50,
					align: 'center',
					title: 'VIP', checked: true
				},
				{
					field: "vip_deadline",
					width: 100,
					align: 'center',
					title: 'VIP到期时间', checked: true
				},
				{
					field: "vip_save_money",
					width: 100,
					align: 'center',
					title: 'VIP累计省钱(中断清零)', checked: true
				},
				{
					field: "register_time",
					width: 100,
					align: 'center',
					title: '注册时间', checked: true
				},
				{
					field: "last_time",
					width: 100,
					align: 'center',
					title: '最近登录', checked: true
				},
			],
			checkHeadShow: false,
			ishow: true,
			energyList: [],
			energyTotal: 0,
			updateEnergyShow: false,
			updateEnergyData: {
				type: 1,
				num: 0,
			},
			updateEnergyRule: {
				num: [{
					type: "number",
					message: '数量值必须大于1',
					trigger: 'change',
					min: 1
				}],
			},
			tabIndex: 2
		}
	},
	mounted: function () {
		//this.searchFormData.searchType = this.searchType.name.value;
		//this.getGroupList();
		let rowDataa=localStorage.getItem('rowData');
		if(rowDataa){
			this.rowData=JSON.parse(rowDataa);
		}
	},
	computed: {
		//充扣款时金额为0 按钮禁用
		editDisabled() {
			if (this.rechargeChargingDialogFormData.money == 0) {
				return true
			}
			return false
		},
		//充扣款时 选择扣款金额最大值
		rechargeChargingMax() {
			if (this.rechargeChargingDialogFormData.type == 2) {
				return parseInt(this.rechargeChargingDialogFormOriData.balance);
			}
			else {
				return 9999999
			}
		},
		ischeckAll() {
			let mm = this.rowData.find(e => !e.checked);
			console.log(mm)
			if (mm) {
				console.log('没有全选')
				return false;
			}
			else {
				console.log('全选')
				return true;
			}
		}
	},
	methods: {
		//选择全部表头
		checkAll() {
			console.log(this.ischeckAll);
			if (!this.ischeckAll) {
				this.rowData.forEach(e => {
					e.checked = true
				})
				this.changea();
			}
			else {
				this.rowData.forEach(e => {
					e.checked = false
				})
				this.changea();
			}
		},
		//能量值修改
		updateEnergy() {
			this.detailDialogShow = false;
			this.updateEnergyShow = true;
		},
		//表头修改
		changea() {
			console.log(this.rowData);
			this.ishow = false;
			setTimeout(() => {
				this.ishow = true;
			}, 50);
			localStorage.setItem('rowData', JSON.stringify(this.rowData));
		},
		// 表格列表
		async getList(currentPage, pageSize) {
			const params = {
				token: this.$store.state.user.token,
				page: currentPage,
				size: pageSize
			}
			this.isLoading = true
			const searchData = this.searchFormData
			// 搜索类型
			// if (this.searchFormData.searchType == this.searchType.name.value) {
			// 	this.searchFormData.nickname = this.searchFormData.searchKey;
			// 	this.searchFormData.fans_id = null;
			// } else {
			// 	this.searchFormData.fans_id = this.searchFormData.searchKey;
			// 	this.searchFormData.nickname = null;
			// }
			// this.searchFormData['sort'] = this.searchFormData.sortType;
			//delete this.searchFormData.sortType;
			// 小区限制
			const group_id = this.$store.state.user.userInfo.group_id
			if (!searchData.group_id && group_id) {
				searchData.group_id = group_id
			}
			const res = await this.$api.Fans.GetFansList(params, searchData)
			this.tableData = res.data;
			for (let v of this.tableData) {
				v.cityText = this.types.city[v.city];
				v.is_white_text = v.is_white === 10 ? '否' : '是';
				if (v.from_phone && v.from_phone.indexOf('|') != -1) {
					v.from_phone = v.from_phone.split('|')[1];
				}
			}
			this.totalPage = res.total
			this.isLoading = false
		},
		// 获取小区信息
		async getGroupList() {
			const params = {
				token: this.$store.state.user.token,
				page: 1,
				size: 1000,
			};
			const res = await this.$api.Device.GetGroupList(params, {});
			console.log("2222");
			this.groupList = res.data;
		},
		// 小区
		async remoteGroup(query) {
			if (query !== '') {
				this.groupLoading = true
				const params = {
					token: this.$store.state.user.token
				}
				const res = await this.$api.Device.GetGroupList(params, {
					group_name: query
				})
				this.groupList = res.data
				this.groupLoading = false
			} else {
				this.groupList = []
			}
		},
		// 重置搜索
		clearSearch() {
			this.searchFormData = {}
		},
		// 关闭弹窗
		closeDialog() {
			this.detailDialogShow = false
			this.rechargeDialogShow = false
			this.chargingDialogShow = false
		},
		// 详情
		async detailOne(row, index) {
			console.log(row)
			const params = {
				token: this.$store.state.user.token,
				fans_id: row.fans_id
			}
			const res = await this.$api.Fans.GetFansDetail(params)
			res.is_white = res.is_white === 20;
			this.detailDialogFormData = res;
			this.detailDialogFormData.is_vip=row.is_vip;

			//车辆
			await this.$api.Fans.GetCarDetail({
				token: this.$store.state.user.token,
				fans_id: row.fans_id
			}).then(res => {
				this.detailCar = res;
			}).catch(err => {
				this.detailCar = [];
			})


			//电卡
			await this.$api.Fans.GetCardDetail({
				token: this.$store.state.user.token,
				fans_id: row.fans_id
			}).then(res => {
				this.detailCardInfo = res;
			}).catch(err => {
				this.detailCardInfo = [];
			})

			//优惠券
			await this.$api.Fans.GetConDetail({
				token: this.$store.state.user.token,
				fans_id: row.fans_id
			}).then(res => {
				this.detailCon = res;
			}).catch(err => {
				this.detailCon = [];
			});
			//获取能量日志
			this.updateEnergyData.fans_id = row.fans_id;
			this.energyGet();

			this.tabIndex = index.toString();
			console.log(this.detailDialogFormData)
			this.detailDialogShow = true;
		},
		energyGet(page = 1){
			this.$api.Market.Energy.Index.log({token: this.$store.state.user.token, fans_id: this.updateEnergyData.fans_id, page:page}).then(res => {
				this.energyList = res.data;
				this.energyTotal = res.total;
			});
		},
		//充扣款类型改变
		changeRechargeChargingType(data) {
			console.log(data);
			if (data == 2) {
				console.log(this.rechargeChargingDialogFormData.money);
				console.log(this.rechargeChargingMax);

				if (this.rechargeChargingDialogFormData.money > this.rechargeChargingMax) {
					this.rechargeChargingDialogFormData.money = 0;
				}
			}
		},
		// 充扣款
		async rechargeChargingOne(row) {
			const params = {
				token: this.$store.state.user.token,
				fans_id: row.fans_id
			}
			const res = await this.$api.Fans.GetFansDetail(params)
			this.rechargeChargingDialogFormOriData = res;
			console.log(this.rechargeChargingDialogFormOriData);
			this.rechargeChargingDialogFormData = {
				fans_id: row.fans_id,
				type: 1,
				money: 0,
				remark: ""
			}
			if (this.$refs.rechargeChargingForm) {
				this.$refs.rechargeChargingForm.resetFields()
			}
			this.rechargeChargingDialogShow = true
		},
		// 清除资格
		async rechargeDkChargingOne(row) {
			this.rechargeDkDialogFormData = {
				fans_id: row.fans_id,
				type: 1,
			}
			if (this.$refs.rechargeDkChargingForm) {
				this.$refs.rechargeDkChargingForm.resetFields()
			}
			this.rechargeDkDialogShow = true
		},
		//能量值保存
		saveUpdateEnergy() {
			this.$refs.updateEnergyForm.validate(async (valid) => {
				if (valid) {
					let res = await this.$api.Market.Energy.Index.change(Object.assign({token: this.$store.state.user.token}, this.updateEnergyData));
					console.log(res);
					this.$notify({
						title: '成功',
						message: '保存成功',
						type: 'success'
					})
					this.updateEnergyShow = false;
					this.$refs.xTable.refreshTable()
				}
			})
		},

		//充扣款保存
		saveRechargeCharging() {
			this.$refs.rechargeChargingForm.validate(async (valid) => {
				if (valid) {
					console.log(this.rechargeChargingDialogFormData);
					if (this.rechargeChargingDialogFormData.type == 1) {
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify({
								fans_id: this.rechargeChargingDialogFormData.fans_id,
								balance: this.rechargeChargingDialogFormData.money,
								gift_money: 0,
								remark: this.rechargeChargingDialogFormData.remark
							})
						}
						await this.$api.Fans.RechargeFans(params)
						this.$notify({
							title: '成功',
							message: '充值成功',
							type: 'success'
						})
						this.rechargeChargingDialogShow = false;
						this.$refs.xTable.refreshTable()
					}
					if (this.rechargeChargingDialogFormData.type == 2) {
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify({
								fans_id: this.rechargeChargingDialogFormData.fans_id,
								balance: this.rechargeChargingDialogFormData.money,
								gift_money: 0,
								remark: this.rechargeChargingDialogFormData.remark
							})
						}
						await this.$api.Fans.ChargingFans(params)
						this.$notify({
							title: '成功',
							message: '扣款成功',
							type: 'success'
						})
						this.rechargeChargingDialogShow = false;
						this.$refs.xTable.refreshTable()
					}
				}
			})
		},
		//清除电卡
		saveRechargeDkCharging() {
			this.$refs.rechargeDkChargingForm.validate(async (valid) => {
				if (valid) {
					const params = {
						token: this.$store.state.user.token,
						form: JSON.stringify({
							fans_id: this.rechargeDkDialogFormData.fans_id,
							type: this.rechargeDkDialogFormData.type,
						})
					}
					await this.$api.Fans.ClearDkFans(params)
					this.$notify({
						title: '成功',
						message: '操作成功',
						type: 'success'
					})
					this.rechargeDkDialogShow = false;
					this.$refs.xTable.refreshTable()
				}
			})
		},

		userFansStatusOper: async function (row) {
			let res = {}
			const params = {
				token: this.$store.state.user.token,
				fans_id: row.fans_id
			}
			if (row.status == 1) {
				res = await this.$api.Fans.DisableFans(params)
			} else {
				res = await this.$api.Fans.EnableFans(params)
			}
			console.log(res)
			let title = '成功'
			let type = 'success'
			if (res.indexOf('成功') == -1) {
				title = '失败'
				type = 'error'
			}
			this.$notify({
				title: title,
				message: res,
				type: type
			})
			this.$refs.xTable.refreshTable()
		},
		onFansWhiteChange: async function () {
			const params = {
				token: this.$store.state.user.token,
				fans_id: this.detailDialogFormData.fans_id,
				is_white: this.detailDialogFormData.is_white ? 20 : 10
			}
			let res = await this.$api.Fans.SetFansIsWhite(params)
			let title = res;
			let type = 'success'
			if (res.indexOf('成功') == -1) {
				type = 'error'
			}
			this.$notify({
				title: title,
				message: res,
				type: type
			})
			this.$refs.xTable.refreshTable()
		}
	}
}
</script>
<style lang="scss" scoped>
.row-title {
	font-size: 14px;
	line-height: 20px;
	padding: 4px 0;
	margin-bottom: 18px;

	&:before {
		content: " ";
		width: 5px;
		height: 20px;
		background: #008e4d;
		display: block;
		float: left;
		margin: 0 10px 0 0;
	}
}

.avatar {
	width: 24px;
	height: 24px;
	display: block;
	margin: 0 auto;
}

.avatarD {
	width: 24px;
	height: 24px;
	display: block;
}

.txt-disable {
	color: #ddd;
}

.avatar-disable {
	filter: grayscale(100%);
}

.checkHead {
	border-radius: 10px;
	border: 1px solid #DCDFE6;
	display: inline-block;
	padding: 10px;
	background: white;
	width: 200px;
	position: absolute;
	top: 45px;
	right: 15px;

	.heads {
		display: flex;
		justify-content: space-between;
		font-size: 12px;
		margin-bottom: 5px;

		.btn {
			color: black;
			padding: 0px;
		}
	}

	.item {
		height: 30px;
		line-height: 30px;
		display: flex;
		justify-content: space-between;
	}
}

.navBar {
	display: flex;
	margin-bottom: 20px;

	div {
		margin-right: 15px;
	}
}

.DetailLine {
	display: flex;
	margin-top: 10px;

	.t1 {
		text-align: right;
		width: 120px;
	}
}

.detailK {
	::v-deep(.el-tabs--border-card) {
		min-height: 500px;
	}
}
</style>
